<template>
    <div v-if="$store.state.persons.currentCompanyAdmin && $store.state.persons.currentCompanyAdmin.id">
        <div class="mb-2 relative">
            <div class="flex justify-between w-full bg-gray-50 pb-2">
                <div class="flex">
                    <div v-show="numberOfRowsChecked && !showArchive">
                        <span class="mr-2"
                            >{{ numberOfRowsChecked }} <span v-trans="'geselecteerd'">geselecteerd</span>: </span
                        ><a @click.prevent="getEmployeeCount" class="btn btn--primary mr-3" href="#" v-trans="'archive'"
                            >Archiveren</a
                        >
                    </div>
                    <a
                        @click.prevent="setStatus()"
                        class="btn btn--primary mr-3"
                        href="#"
                        v-show="numberOfRowsChecked && showArchive"
                        v-trans="'activate-again'"
                        >Opnieuw activeren</a
                    >
                    <div class="form-group">
                        <input
                            :placeholder="$trans.getTranslation('filter', 'Filter')"
                            autofocus
                            class="form-control"
                            type="text"
                            v-model="searchTermDelayed"
                        />
                        <div class="filter-clear">
                            <button @click.prevent="searchTermDelayed = ''">
                                <CloseIcon />
                            </button>
                        </div>
                    </div>
                </div>

                <div class="flex justify-end" v-if="!$store.getters.isViewer">
                    <a
                        @click.prevent="addCompany"
                        class="flex items-center btn btn btn--primary btn--icon"
                        href="#"
                        v-show="!showArchive"
                    >
                        <div v-trans="'add'">Toevoegen</div>
                        <PlusIcon />
                    </a>
                </div>
            </div>

            <hr />

            <div class="form-group flex items-center">
                <div :key="value" class="mr-4" v-for="(value, propertyName) in companyTypes">
                    <checkbox
                        class="mr-1 text-sm"
                        :label="$trans.getTranslation('company-type-' + propertyName, propertyName)"
                    >
                        <input :id="value" :value="value" class="mr-1" type="checkbox" v-model="companyTypesToSelect" />
                    </checkbox>
                </div>

                <div class="ml-auto">
                    <!--					<input class="mr-1" id="isArchived" type="checkbox" v-model="showArchive">-->
                    <!--					<label for="isArchived" v-trans="'show-archive'">Toon archief</label>-->

                    <checkbox class="mr-1 text-sm" :label="$trans.getTranslation('show-archive', 'Toon archief')">
                        <input type="checkbox" v-model="showArchive" />
                    </checkbox>
                </div>
            </div>
        </div>

        <div class="alert alert--warning" v-show="errorMsg">{{ errorMsg }}</div>

        <table class="table">
            <thead>
                <tr>
                    <th><input @change="onHeaderCheckboxChanged" type="checkbox" v-model="allChecked" /></th>
                    <th @click="sortOn('title')" class="cursor-pointer">
                        <span v-trans="'company'">Bedrijf</span> <i :class="sortDirectionArrowClass('title')"></i>
                    </th>
                    <th @click="sortOn('isAccessAllowed')" class="cursor-pointer">
                        <span v-trans="'has-access'">Heeft toegang</span>
                        <i :class="sortDirectionArrowClass('isAccessAllowed')"></i>
                    </th>
                    <th @click="sortOn('numberOfEmployees')" class="cursor-pointer">
                        <span v-trans="'employees'">Medewerkers</span>
                        <i :class="sortDirectionArrowClass('numberOfEmployees')"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :key="company.id" v-for="company in filteredCompanies">
                    <td style="width: 40px"><input type="checkbox" v-model="company.checked" /></td>
                    <td class="cursor-pointer">
                        <div @click.prevent="updateCompany(company)">
                            <span class="text-primary-normal hover:underline cursor-pointer">{{ company.title }}</span>
                            <span class="helper" v-trans="'click-to-edit'">click to edit</span>
                        </div>
                    </td>
                    <td v-show="company.isAccessAllowed" v-trans="'yes'">Ja</td>
                    <td v-show="!company.isAccessAllowed" v-trans="'no'">Nee</td>
                    <td>{{ company.numberOfEmployees }}</td>
                    <!--				<td>
									<DotsHorizontalIcon class="cursor-pointer text-lg"/>
								</td>-->
                </tr>
            </tbody>
        </table>

        <div class="c-preloader mt-4 c-preloader--fixed" v-show="isLoading"><i></i><i></i><i></i></div>

        <add-or-edit-company
            :caption="companyModalCaption"
            :company="companyToEditOrAdd"
            @cancel="onCancelPopupCompany"
            @saveSucceeded="onCompanySaveSucceeded"
            v-if="showAddCompanyModal"
        />

        <dynamic-modal
            :header-text="$trans.getTranslation('warning', 'Opgepast')"
            :positiveText="$trans.getTranslation('yes', 'Ja')"
            :negative-text="$trans.getTranslation('no', 'Nee')"
            @close="closeArchiveCompanyModal"
            @confirm="confirmCompanyDeactivation"
            @cancel="closeArchiveCompanyModal"
            :buttons="3"
            v-show="archiveCompanyModal"
        >
            <div class="mx-auto text-center" slot="content">
                <div>{{ deactivateWarning }}</div>
            </div>
        </dynamic-modal>
    </div>
</template>

<script>
import { GET_ALL_COMPANIES_QUERY_WITH_FILTER } from "../../graphql/queries/Company";
import CloseIcon from "vue-material-design-icons/Close.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import {
    ACTION_SET_COMPANY_SEARCH_TERM,
    ACTION_SET_COMPANY_TYPES_TO_SELECT,
    ACTION_SET_CURRENT_COMPANY,
    ACTION_SET_CURRENT_COMPANY_ADMIN,
    COMPANY_TYPE,
} from "../../store/constants";
import AddOrEditCompany from "../../components/AddOrEditCompany";
import { GET_PERSONS_FROM_COMPANY2 } from "../../graphql/queries/Person";
import Checkbox from "../../components/Checkbox";
import DynamicModal from "../../components/DynamicModal";
import { GET_USERS } from "@/graphql/queries/User";

export default {
    name: "CompanyAdminCompaniesList",
    components: {
        AddOrEditCompany,
        CloseIcon,
        PlusIcon,
        Checkbox,
        DynamicModal,
        // DotsHorizontalIcon
    },
    data() {
        return {
            searchTermDelayed: "",
            errorMsg: "",
            isLoading: false,
            status: "live",
            allChecked: false,
            showAddCompanyModal: false,
            companyToEditOrAdd: {},
            showArchive: false,
            archiveCompanyModal: false,
            selectedCompanyEmployees: 0,
            selectedAdminAccounts: [],
            waitingForConfirm: false,
            tableSort: {
                sortDirectionPerField: {
                    title: 1,
                    numberOfEmployees: 1,
                    isAccessAllowed: 1,
                },
                lastSortField: "title",
                lastSortOrder: 1,
            },
        };
    },

    apollo: {
        companies: {
            query: GET_ALL_COMPANIES_QUERY_WITH_FILTER,
            fetchPolicy: "cache-and-network",
            variables() {
                this.errorMsg = "";
                return {
                    status: this.showArchive ? "disabled" : "live",
                    companyTypes: this.companyTypesToSelect,
                    search: this.searchTerm ? `*${this.searchTerm}*` : "",
                    limit: 100,
                    orderBy: this.tableSort.lastSortField + " " + (this.tableSort.lastSortOrder === 1 ? "asc" : "desc"),
                };
            },
            update(data) {
                this.searchTermDelayed = this.searchTerm;
                if (data.entries) {
                    this.errorMsg = "";
                    data.entries.forEach((entry) => {
                        // we want these fields not deeply nested for easy sorting!
                        this.normalizeField(entry);
                    });
                    this.allChecked = false;
                    return data.entries;
                } else {
                    return [];
                }
            },
            watchLoading(isLoading) {
                this.isLoading = isLoading;
            },
            error(e) {
                if (e.networkError && parseInt(e.networkError.response.status) === 401) {
                    this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                } else {
                    this.errorMsg = JSON.stringify(e);
                }
            },
            skip() {
                return this.waitingForConfirm;
            },
        },
    },

    watch: {
        searchTermDelayed(newValue) {
            // this.isLoading = true
            clearTimeout(this.searchIid);
            this.searchIid = setTimeout(() => {
                this.searchTerm = newValue;
            }, 600);
        },
    },

    computed: {
        deactivateWarning() {
            let r = this.$trans.getTranslation(
                "disable-company--body",
                "Indien u dit bedrijf deactiveert zullen 40 contractors gedeactiveerd worden"
            );
            return r
                ? r
                      .replace("$AMOUNT", this.selectedCompanyEmployeesLength)
                      .replace("$ADMINS", this.selectedAdminAccounts.map((a) => a.email).join(", "))
                : "";
        },
        searchTerm: {
            set(value) {
                this.$store.dispatch(ACTION_SET_COMPANY_SEARCH_TERM, value);
            },
            get() {
                return this.$store.state.companies.companySearchTerm;
            },
        },

        numberOfRowsChecked() {
            if (!this.companies) {
                return 0;
            } else {
                return this.companies.filter((company) => company.checked).length;
            }
        },
        companyModalCaption() {
            return this.companyToEditOrAdd.id
                ? this.$trans.getTranslation("change-company")
                : this.$trans.getTranslation("add-company");
        },

        filteredCompanies() {
            // if (this.search.length === 0) return this.companies;
            // return this.companies.filter(company => {
            // 	let searchString = Utils.getSearchString(company.title);
            // 	return searchString.indexOf(Utils.getSearchString(this.search)) > -1;
            // });
            if (!this.companies) {
                return [];
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.companies.sort((a, b) => {
                //console.log(this.tableSort.lastSortField);
                let aField;
                let bField;
                if (typeof a[this.tableSort.lastSortField] === "string") {
                    aField = a[this.tableSort.lastSortField].toLowerCase();
                    bField = b[this.tableSort.lastSortField].toLowerCase();
                } else {
                    aField = a[this.tableSort.lastSortField];
                    bField = b[this.tableSort.lastSortField];
                }
                if (aField > bField) {
                    return 1 * this.tableSort.sortDirectionPerField[this.tableSort.lastSortField];
                }
                if (bField > aField) {
                    return -1 * this.tableSort.sortDirectionPerField[this.tableSort.lastSortField];
                }
                return 0;
            });
        },

        companyTypes() {
            return COMPANY_TYPE;
        },

        companyTypesToSelect: {
            set(value) {
                this.$store.dispatch(ACTION_SET_COMPANY_TYPES_TO_SELECT, value);
            },
            get() {
                return this.$store.state.companies.companyTypesToSelect;
            },
        },
        selectedCompanyEmployeesLength() {
            if (this.selectedCompanyEmployees !== undefined) return this.selectedCompanyEmployees.length;
            else return 0;
        },
    },
    created() {
        this.$store.subscribe((mutation) => {
            if (mutation.type === ACTION_SET_CURRENT_COMPANY_ADMIN && this.$apollo.queries.companies) {
                this.$apollo.queries.companies.refetch();
            }
        });
    },
    methods: {
        normalizeField(entry) {
            entry.checked = false;
            return entry;
        },

        sortOn(fieldName) {
            if (!this.tableSort.sortDirectionPerField[fieldName]) {
                this.tableSort.sortDirectionPerField[fieldName] = 1;
            }
            //last sorting was on this field?
            if (this.tableSort.lastSortField === fieldName) {
                //reverse direction
                this.tableSort.sortDirectionPerField[fieldName] = this.tableSort.sortDirectionPerField[fieldName] * -1;
            }
            this.tableSort.lastSortField = fieldName;
            this.tableSort.lastSortOrder = this.tableSort.sortDirectionPerField[fieldName];
        },
        onHeaderCheckboxChanged(e) {
            this.companies.forEach((company) => {
                company.checked = e.target.checked;
            });
            //trigger reactivity
            this.companies = [].concat(this.companies);
        },

        sortDirectionArrowClass(fieldName) {
            if (this.tableSort.lastSortField === fieldName) {
                return this.tableSort.lastSortOrder === 1 ? "arrow down" : "arrow up";
            } else {
                return "invisible";
            }
        },

        /**
         * the status is "live" or "disabled"
         * to set to disabled, you have to set enabled = false!
         */
        setStatus(ids) {
            this.isLoading = true;

            let promises = [];

            // ids can be passed by another function.
            if (ids === undefined) {
                ids = [];
                this.companies.forEach((company) => {
                    if (company.checked) {
                        ids.push(company.id);
                    }
                });
            }
            ids.forEach((id) => {
                const restCall = this.$axios({
                    url: `rest/v1/entry/change-status`,
                    method: "post",
                    responseType: "json",
                    data: {
                        entryId: id,
                        state: this.showArchive ? 1 : 0,
                    },
                }).then((val) => {
                    console.log("change status done for ", id, val);
                });
                promises.push(restCall);
            });

            Promise.all(promises)
                // .then(promiseValues => {
                //     console.log("promiseValues:", promiseValues);
                //     //loop all resolved promises to update status
                //     // promiseValues.forEach(promise => {
                //     // 	const indexToRemove = this.persons.findIndex(p => p.id === promise.data.upsertPerson.id);
                //     // 	this.persons.splice(indexToRemove, 1);
                //     // });
                // })
                .catch((e) => {
                    let err;
                    if (e.networkError && e.networkError.result && e.networkError.result.error) {
                        err = e.networkError.result.error.split("\r\n").join("");
                    } else if (
                        e.networkError &&
                        e.networkError.result &&
                        e.networkError.result.errors &&
                        e.networkError.result.errors[0] &&
                        e.networkError.result.errors[0].message
                    ) {
                        err = e.networkError.result.errors[0].message;
                    } else {
                        err = e.message;
                    }
                    const error = this.$trans.getTranslation(err, err);
                    console.log("Set Status Error", error);
                    this.errorMsg = error;
                })
                .finally(() => {
                    this.$apollo.queries.companies.refetch();
                    this.isLoading = false;
                    this.waitingForConfirm = false;
                });
        },

        async getUsersForCompanyIds(companyIds) {
            this.selectedAdminAccounts = [];
            return this.$apollo
                .query({
                    query: GET_USERS,
                    variables: {
                        userIds: null,
                        companyId: companyIds,
                        craftUserTypeSlugs: ["contractoradministrator"],
                    },
                    fetchPolicy: "network-only",
                })
                .then((response) => {
                    console.log("Gert: response:", response);

                    if (response.data && response.data.users.length > 0) {
                        console.log("getUsersForCompanyIds return:", response.data.users);
                        this.selectedAdminAccounts = response.data.users;
                    } else {
                        // throw new Error("getUsersForCompanyIds", response);
                    }
                });
        },

        closeArchiveCompanyModal() {
            this.archiveCompanyModal = false;
        },
        async confirmCompanyDeactivation() {
            // 	this.waitingForConfirm = false;

            let companyIds = [];
            this.companies.forEach((company) => {
                if (company.checked) {
                    companyIds.push(company.id);
                    console.log("company", company);
                }
            });

            const ids = [];
            for (let i = 0; i < this.selectedCompanyEmployeesLength; i++) {
                const entry = this.selectedCompanyEmployees[i];
                ids.push(entry.id);
            }

            this.isLoading = true;

            //1. delete all admins
            for (const admin of this.selectedAdminAccounts) {
                await this.deleteUser(admin.id);
            }

            //2. set state to disabled for all persons
            this.$axios({
                url: `rest/v1/entry/change-status-bulk`,
                method: "post",
                responseType: "json",
                data: {
                    entryIds: ids,
                    state: this.showArchive ? 1 : 0,
                },
            }).then((val) => {
                console.log("change status done for ", ids.length, val);
                this.setStatus(companyIds);
                this.archiveCompanyModal = false;
            });
        },

        async deleteUser(userId) {
            return this.$axios({
                url: `rest/v1/user/delete-user`,
                // url: `${process.env.VUE_APP_REST_ENDPOINT}/actions/visit-registration-platform/user/delete-user`,
                method: "post",
                data: {
                    userId,
                },
            })
                .then((response) => {
                    console.log("deleteUser response:", response);
                })
                .catch((reject) => {
                    this.error = JSON.stringify(reject);
                });
        },

        async getEmployeeCount() {
            this.waitingForConfirm = true;
            let ids = [];
            this.companies.forEach((company) => {
                if (company.checked) {
                    ids.push(company.id);
                }
            });
            await this.$apollo
                .query({
                    query: GET_PERSONS_FROM_COMPANY2,
                    variables: {
                        companyId: ids,
                        status: "live",
                    },
                    fetchPolicy: "network-only",
                })
                .then(
                    async (result) => {
                        // console.log(result);
                        // console.log(result.data.entries.length + " will be disabled!");

                        await this.getUsersForCompanyIds(ids);

                        if (result.data.entries.length === 0) {
                            this.waitingForConfirm = false;
                            this.setStatus();
                        } else {
                            this.selectedCompanyEmployees = result.data.entries;

                            this.archiveCompanyModal = true;
                        }
                        /*

					 */
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        addCompany() {
            this.showAddCompanyModal = true;
            this.companyToEditOrAdd = {
                id: null,
                title: "",
                isAccessAllowed: true,
                companyType: [{ id: COMPANY_TYPE.CONTRACTOR }],
            };
        },
        onCancelPopupCompany() {
            this.showAddCompanyModal = false;
        },
        updateCompany(company) {
            this.companyToEditOrAdd = company;
            this.$store.dispatch(ACTION_SET_CURRENT_COMPANY, company);
            this.$router.push({ name: "onlineContractorAdmin" });
        },
        onCompanySaveSucceeded(company) {
            this.showAddCompanyModal = false;
            this.searchTerm = company.title;
            this.$apollo.queries.companies.refetch();
        },
    },
};
</script>
