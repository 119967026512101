<template>
    <div>
        <div class="flex justify-between w-full bg-gray-50 pb-2">
            <div class="flex">
                <div v-show="numberOfRowsChecked">
                    <div class="inline-block mr-2">
                        {{ numberOfRowsChecked }} <span v-trans="'geselecteerd'">geselecteerd</span>:
                    </div>
                    <span v-if="!$store.getters.isViewer">
                        <InvitePersons
                            v-show="numberOfRowsChecked && !showArchive"
                            :persons="persons?.filter((p) => p.checked) || []"
                            @refetchPersons="$apollo.queries.persons.refetch()"
                        />
                        <a
                            @click.prevent="setStatus"
                            class="btn btn--primary mr-3"
                            href="#"
                            v-show="numberOfRowsChecked && !showArchive"
                            >Archiveren</a
                        >
                        <a
                            @click.prevent="setStatus"
                            class="btn btn--primary mr-3"
                            href="#"
                            v-show="numberOfRowsChecked && showArchive"
                            >Opnieuw activeren</a
                        >
                    </span>
                </div>
                <div class="form-group" v-show="!numberOfRowsChecked">
                    <input
                        :placeholder="$trans.getTranslation('filter', 'Filter')"
                        autofocus
                        class="form-control"
                        type="text"
                        v-model="searchTermDelayed"
                    />
                    <div class="filter-clear">
                        <button @click.prevent="searchTermDelayed = ''">
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex justify-end" v-if="!$store.getters.isViewer">
                <a
                    @click.prevent="addEmployee"
                    class="flex items-center btn btn btn--primary btn--icon"
                    href="#"
                    v-show="!showArchive"
                >
                    <div v-trans="'add'">Toevoegen</div>
                    <PlusIcon />
                </a>
            </div>
        </div>

        <hr />

        <div class="form-group flex items-center">
            <div class="ml-auto">
                <checkbox :label="$trans.getTranslation('show-archive', 'Toon archief')" class="mr-1 text-sm">
                    <input type="checkbox" v-model="showArchive" />
                </checkbox>
            </div>
        </div>

        <div class="c-preloader mt-4 c-preloader--fixed" v-show="isLoading"><i></i><i></i><i></i></div>
        <div class="alert alert--warning" v-show="errorMsg">{{ errorMsg }}</div>

        <table class="table">
            <thead>
                <tr>
                    <th width="35"><input @change="onHeaderCheckboxChanged" type="checkbox" v-model="allChecked" /></th>
                    <th @click="sortOn('firstName')" class="cursor-pointer whitespace-no-wrap" width="20%">
                        <span class="mr-2" v-trans="'first-name'">Voornaam</span>
                        <i :class="sortDirectionArrowClass('firstName')"></i>
                    </th>
                    <th @click="sortOn('lastName')" class="cursor-pointer whitespace-no-wrap" width="20%">
                        <span class="mr-2" v-trans="'last-name'">Familienaam</span>
                        <i :class="sortDirectionArrowClass('lastName')"></i>
                    </th>
                    <th v-if="!forContractorCompany" v-trans="'company'" width="20%">Bedrijf</th>
                    <th v-trans="'status'">Status</th>
                    <th @click="sortOn('dateCreated')" class="cursor-pointer whitespace-no-wrap" width="20%">
                        <span class="mr-2" v-trans="'Aangemaakt'">Aangemaakt</span>
                        <i :class="sortDirectionArrowClass('dateCreated')"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :key="person.id" v-for="person in sortedAndfilteredPersons">
                    <td style="width: 40px"><input type="checkbox" v-model="person.checked" /></td>
                    <td class="cursor-pointer">
                        <div
                            @click.prevent="updateEmployee(person)"
                            class="text-primary-normal hover:underline cursor-pointer"
                        >
                            {{ person.firstName }} <span class="helper" v-trans="'click-to-edit'">click to edit</span>
                        </div>
                    </td>
                    <td class="cursor-pointer">
                        <div
                            @click.prevent="updateEmployee(person)"
                            class="text-primary-normal hover:underline cursor-pointer"
                        >
                            {{ person.lastName }} <span class="helper" v-trans="'click-to-edit'">click to edit</span>
                        </div>
                    </td>
                    <td v-if="!forContractorCompany">{{ personCompany(person) }}</td>
                    <td class="p-1">
                        <learning-track-pills :person="person" />
                    </td>
                    <td class="p-1" v-html="Utils.toLocaleDateString(person.dateCreated)"></td>
                </tr>
            </tbody>
        </table>

        <add-or-edit-employee
            :caption="employeeModalCaption"
            :person="personToEditOrAdd"
            @cancel="onCancelPopupEmployee"
            @saveSucceeded="onEmployeeSaveSucceeded"
            v-if="showAddCompanyModal"
        />
    </div>
</template>

<script>
import { GET_PERSONS2, GET_PERSONS_FROM_COMPANY2 } from "../graphql/queries/Person";
import CloseIcon from "vue-material-design-icons/Close.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import {
    ACTION_SET_CURRENT_COMPANY,
    ACTION_SET_CURRENT_CONTRACTOR,
    ACTION_SET_PERSON_SEARCH_TERM,
} from "../store/constants";
import AddOrEditEmployee from "./AddOrEditEmployee";
import LearningTrackPills from "./LearningTrackPills";
import { Utils } from "../utils/utils.js";
import Checkbox from "./Checkbox";
import moment from "moment";
import InvitePersons from "@/components/InvitePersons";

export default {
    name: "ContractorList",
    components: {
        InvitePersons,
        Checkbox,
        LearningTrackPills,
        AddOrEditEmployee,
        CloseIcon,
        PlusIcon,
    },
    props: {
        forContractorCompany: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            Utils,
            moment,
            searchIid: -1,
            isLoading: false,
            allChecked: false,
            showAddCompanyModal: false,
            personToEditOrAdd: {},
            showArchive: false,
            errorMsg: "",
            tableSort: {
                sortDirectionPerField: {
                    firstName: 1,
                    lastName: 1,
                    dateCreated: -1,
                },
                lastSortField: "dateCreated",
                lastSortOrder: -1,
            },
            searchTermDelayed: "",
        };
    },

    watch: {
        searchTermDelayed(newValue) {
            // this.isLoading = true
            clearTimeout(this.searchIid);
            this.searchIid = setTimeout(() => {
                this.searchTerm = newValue;
            }, 1200);
        },
    },

    apollo: {
        persons() {
            return {
                query: this.forContractorCompany ? GET_PERSONS_FROM_COMPANY2 : GET_PERSONS2,
                //pollInterval: 3000,
                fetchPolicy: "cache-and-network",
                variables() {
                    this.errorMsg = "";
                    const vars = {
                        companyId: this.forContractorCompany ? this.$store.state.companies.currentCompany.id : "",
                        status: this.showArchive ? "disabled" : "live",
                        search: this.searchTerm ? `*${this.searchTerm}*` : "",
                        limit: 100,
                        orderBy:
                            this.tableSort.lastSortField + " " + (this.tableSort.lastSortOrder === 1 ? "asc" : "desc"),
                    };
                    // console.log("persons vars", vars);
                    return vars;
                },
                update(data) {
                    this.searchTermDelayed = this.searchTerm;
                    if (data.entries) {
                        data.entries.forEach((entry) => {
                            // we want these fields not deeply nested for easy sorting!
                            this.normalizeField(entry);
                        });
                        this.allChecked = false;

                        return data.entries;
                    } else {
                        return [];
                    }
                },
                error(e) {
                    console.log(parseInt(e?.networkError?.response?.status));
                    if (e.networkError && parseInt(e?.networkError?.response?.status) === 401) {
                        this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                    } else {
                        this.errorMsg = JSON.stringify(e);
                    }
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
                skip() {
                    return this.$store.getters.currentUserId === 0;
                },
            };
        },
    },
    computed: {
        searchTerm: {
            set(value) {
                this.$store.dispatch(ACTION_SET_PERSON_SEARCH_TERM, value);
            },
            get() {
                return this.$store.state.persons.personSearchTerm;
            },
        },

        numberOfRowsChecked() {
            if (!this.persons) {
                return 0;
            } else {
                return this.persons.filter((person) => person.checked).length;
            }
        },
        employeeModalCaption() {
            return this.personToEditOrAdd.id
                ? this.$trans.getTranslation("werknemer-wijzigen")
                : this.$trans.getTranslation("werknemer-toevoegen");
        },
        sortedAndfilteredPersons() {
            if (!this.persons) {
                return [];
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.persons.sort((a, b) => {
                if (a[this.tableSort.lastSortField].toLowerCase() > b[this.tableSort.lastSortField].toLowerCase()) {
                    return 1 * this.tableSort.sortDirectionPerField[this.tableSort.lastSortField];
                }
                if (b[this.tableSort.lastSortField].toLowerCase() > a[this.tableSort.lastSortField].toLowerCase()) {
                    return -1 * this.tableSort.sortDirectionPerField[this.tableSort.lastSortField];
                }
                return 0;
            });

            // // still filter on the already found content for quick sorting
            // if (this.search.length === 0) return this.persons;
            // return this.persons.filter(person => {
            // 	//console.log(person.company[0]);
            //
            // 	let searchString = person.firstName + "|" + person.lastName;
            // 	if(person.company[0]){
            // 		searchString += "|" + person.company[0].title;
            // 	}
            // 	searchString = Utils.getSearchString(searchString);
            // 	//let statusOk = person.status === this.status;
            // 	return /*statusOk && */searchString.indexOf(Utils.getSearchString(this.search)) > -1;
            // });
        },
    },
    methods: {
        normalizeField(entry) {
            if (!entry.learningTrackResults) {
                entry.learningTrackResults = [];
            }
            //entry.checked = false;
            return entry;
        },

        sortDirectionArrowClass(fieldName) {
            if (this.tableSort.lastSortField === fieldName) {
                if (!this.tableSort.sortDirectionPerField[fieldName]) {
                    this.tableSort.sortDirectionPerField[fieldName] = 1;
                }
                return this.tableSort.sortDirectionPerField[fieldName] === 1 ? "arrow down" : "arrow up";
            } else {
                return "invisible";
            }
        },

        sortOn(fieldName) {
            if (!this.tableSort.sortDirectionPerField[fieldName]) {
                this.tableSort.sortDirectionPerField[fieldName] = 1;
            }
            //last sorting was on this field?
            if (this.tableSort.lastSortField === fieldName) {
                //reverse direction
                this.tableSort.sortDirectionPerField[fieldName] = this.tableSort.sortDirectionPerField[fieldName] * -1;
            }
            this.tableSort.lastSortField = fieldName;
            this.tableSort.lastSortOrder = this.tableSort.sortDirectionPerField[fieldName];
        },
        onHeaderCheckboxChanged(e) {
            this.persons.forEach((person) => {
                person.checked = e.target.checked;
            });
            //trigger reactivity
            this.persons = [].concat(this.persons);
        },

        /**
         * the status is "live" or "disabled"
         * to set to disabled, you have to set enabled = false!
         */
        setStatus() {
            this.isLoading = true;
            let ids = [];
            let promises = [];
            this.persons.forEach((person) => {
                if (person.checked) {
                    ids.push(person.id);
                }
            });
            ids.forEach((id) => {
                const restCall = this.$axios({
                    url: `rest/v1/entry/change-status`,
                    method: "post",
                    responseType: "json",
                    data: {
                        entryId: id,
                        state: this.showArchive ? 1 : 0,
                    },
                }).then((val) => {
                    console.log("change status done for ", id, val);
                });
                promises.push(restCall);
            });

            Promise.all(promises)
                // .then(promiseValues => {
                //     // console.log("Gert: promiseValues:", promiseValues);
                //     //loop all resolved promises to update status
                //     // promiseValues.forEach(promise => {
                //     // 	const indexToRemove = this.persons.findIndex(p => p.id === promise.data.upsertPerson.id);
                //     // 	this.persons.splice(indexToRemove, 1);
                //     // });
                // })
                .catch((e) => {
                    let err;
                    if (e.networkError && e.networkError.result && e.networkError.result.error) {
                        err = e.networkError.result.error.split("\r\n").join("");
                    } else if (
                        e.networkError &&
                        e.networkError.result &&
                        e.networkError.result.errors &&
                        e.networkError.result.errors[0] &&
                        e.networkError.result.errors[0].message
                    ) {
                        err = e.networkError.result.errors[0].message;
                    } else {
                        err = e.message;
                    }
                    const error = this.$trans.getTranslation(err, err);
                    console.log("Set Status Error", error);
                    this.errorMsg = error;
                })
                .finally(() => {
                    this.$apollo.queries.persons.refetch();
                    this.isLoading = false;
                });
        },
        addEmployee() {
            this.showAddCompanyModal = true;
            this.personToEditOrAdd = { company: [{ id: this.$store.state.companies.currentCompany.id }] };
            this.$store.dispatch(ACTION_SET_PERSON_SEARCH_TERM, "");
        },
        onCancelPopupEmployee() {
            this.showAddCompanyModal = false;

            // Fix for IE
            if (Utils.detectIE() !== false) window.location.reload();
        },
        updateEmployee(person) {
            this.personToEditOrAdd = person;
            this.$store.dispatch(ACTION_SET_CURRENT_COMPANY, person.company[0]);
            this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR, person);
            this.$router.push({ name: "onlineContractorProfile" });
        },
        onEmployeeSaveSucceeded(person) {
            // console.log(person);
            this.showAddCompanyModal = false;
            // Fix for IE
            if (Utils.detectIE() !== false) window.location.reload();

            this.$apollo.queries.persons.refetch();
            this.searchTerm = person.firstName + " " + person.lastName;
        },
        personCompany(person) {
            if (!person.company || person.company.length === 0) {
                return person.companyName;
            }
            return person.company[0].title;
        },
    },
};
</script>
