<template>
    <div class="container-50">
        <div class="flex justify-center my-10">
            <router-link :to="{ name: 'home' }"><img src="img/css/aluminium-duffel-logo.webp" /></router-link>
        </div>

        <p class="p text-center">Dit is een technische pagina, enkel van nut voor administrators.</p>
        <p class="p text-center">Ben je hier per ongeluk terechtgekomen? Geen probleem:</p>
        <p class="p text-center">
            <router-link :to="{ name: 'online' }" class="btn btn--primary btn--lg w-full mb-5"
                >ga hier verder</router-link
            >
            voor de portaal site.
        </p>

        <div class="text-center mt-20 bg-gray-300 p-8 rounded-lg">
            <p class="font-bold uppercase">Admin links</p>
            <p>
                <router-link :to="{ name: 'online' }" class="underline text-primary-normal" tag="button">
                    Online site
                </router-link>
            </p>
            <p>
                <router-link :to="{ name: 'onsite' }" class="underline text-primary-normal" tag="button">
                    Check in & out app (iPads)
                </router-link>
            </p>
            <p>
                <router-link :to="{ name: 'test' }" class="underline text-primary-normal" tag="button">
                    Contractor exam (on site)
                </router-link>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "home",
    components: {},
    data() {
        return {};
    },
};
</script>
