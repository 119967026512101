<template>
    <div>
        <div
            class="mb-4 flex items-center alert alert--info"
            v-if="!$store.state.persons.currentCompanyAdmin.id && !$store.state.persons.currentContractorAdmin.id"
        >
            <InformationOutline class="text-2xl mr-3 mb-1" />
            <p class="text-sm" v-trans="'profile-info'">Controleer je gegevens en vul ze eventueel verder aan.</p>
        </div>
        <!--v-if="personToEdit && personToEdit.id"-->
        <div class="mb-6" v-show="!showCapturePhoto">
            <div
                class="user-photo photo-size"
                style="background: url('img/css/user.png') no-repeat center / cover #b9c3d1"
            >
                <img :src="personToEdit?.photo[0].url" v-if="personToEdit?.photo.length > 0" />
                <div
                    @click="showCapturePhoto = true"
                    class="action cursor-pointer"
                    v-if="$store.state.persons.currentCompanyAdmin.id"
                >
                    <CameraIcon />
                </div>
            </div>
            <div
                class="mt-3 mb-5 flex items-center alert alert--info"
                v-if="personToEdit?.photo.length === 1 && !personToEdit?.photo[0].url"
            >
                <InformationOutline class="text-2xl mr-3 mb-1" />
                <p class="text-sm" v-trans="'photo-invisble-info'">
                    De foto wordt pas zichtbaar nadat de persoon wordt opgeslagen.
                </p>
            </div>
        </div>

        <capturePhoto
            :person-to-edit="personToEdit"
            @cancel="showCapturePhoto = false"
            @save="onSavePhotoCapture"
            v-if="showCapturePhoto"
        />

        <form v-if="personToEdit" v-show="!showCapturePhoto">
            <div class="flex flex-wrap md:flex-no-wrap">
                <div class="form-group mb-5 w-full md:w-1/2 md:mr-5">
                    <label
                        ><span v-trans="'first-name'">Voornaam</span>
                        <asterisk-icon />
                    </label>
                    <input
                        class="form-control"
                        ref="firstName"
                        required
                        type="text"
                        v-bind:readonly="isLoading || isDisabled"
                        v-model.trim="personToEdit.firstName"
                    />
                </div>
                <div class="form-group mb-5 md:w-1/2 w-full">
                    <label
                        ><span v-trans="'last-name'">Familienaam</span>
                        <asterisk-icon />
                    </label>
                    <input
                        class="form-control"
                        required
                        type="text"
                        v-bind:readonly="isLoading || isDisabled"
                        v-model.trim="personToEdit.lastName"
                    />
                </div>
            </div>

            <!--<div class="flex">-->
            <div class="form-group mb-5">
                <label v-trans="'email'" for="email">Email</label>
                <input
                    :readonly="isLoading || isDisabled"
                    class="form-control"
                    type="email"
                    id="email"
                    v-model.trim="personToEdit.email"
                />
                <!--				<small class="form-text text-muted">We delen nooit emails met derden.</small>-->
            </div>

            <div class="flex flex-wrap md:flex-no-wrap">
                <div
                    class="form-group mb-5 w-full"
                    v-if="personToEdit.languageCategorie && personToEdit.languageCategorie.length > 0"
                    :class="{ 'md:mr-5  md:w-1/2': $store.state.persons.currentCompanyAdmin.id }"
                >
                    <label
                        ><span v-trans="'language'">Taal</span>
                        <asterisk-icon />
                    </label>
                    <select :disabled="isDisabled" class="form-control" v-model="personToEdit.languageCategorie[0].id">
                        <option :key="language.id" :value="language.id" v-for="language in languages">
                            {{ language.title }}
                        </option>
                    </select>
                </div>

                <div class="form-group mb-5 md:w-1/2 w-full" v-if="$store.state.persons.currentCompanyAdmin.id">
                    <label v-trans="'company'">Bedrijf</label>
                    <div
                        class="c-preloader ml-4"
                        style="transform: translate(-50px, 43px) scale(0.45); z-index: 2; display: inline-block"
                        v-if="isCompanyLoading"
                    >
                        <i></i><i></i><i></i>
                    </div>
                    <select :disabled="isDisabled" class="form-control" v-model="personToEdit.company[0].id">
                        <option :key="company.id" v-bind:value="company.id" v-for="company in companies">
                            {{ company.title }}
                        </option>
                    </select>

                    <p v-show="!personToEdit.company[0].id && personToEdit.companyName">
                        <span v-trans="'unknown-company'">Ongekend bedrijf</span>: {{ personToEdit.companyName }}
                    </p>
                </div>
            </div>

            <div class="flex flex-wrap md:flex-no-wrap">
                <div class="form-group mb-5 w-full md:w-1/2 md:mr-5">
                    <label v-trans="'license-plate'">Nummerplaat wagen</label>
                    <input
                        :readonly="isLoading || isDisabled"
                        class="form-control"
                        type="text"
                        v-model.trim="personToEdit.licensePlate"
                    />
                </div>

                <div class="form-group mb-5 w-full md:w-1/2">
                    <label v-trans="'mobile-phone'">Mobiele telefoon</label>
                    <input
                        :readonly="isLoading || isDisabled"
                        class="form-control"
                        type="text"
                        v-model.trim="personToEdit.mobilePhone"
                    />
                </div>
            </div>

            <div class="form-group py-4" v-if="!personToEdit.id && $store.state.persons.currentCompanyAdmin.id">
                <p class="mb-2">
                    <span v-trans="'Uitnodiging voor veiligheidstest'">Uitnodiging voor veiligheidstest</span>
                    <asterisk-icon />
                </p>
                <div class="flex gap-8">
                    <span v-for="lt in $store.state.persons.learningTracks" :key="lt.slug">
                        <Radiobutton
                            :label="$trans.getTranslation('courseTitle-' + lt.slug, lt.title)"
                            class="mr-1 text-sm"
                            v-if="true"
                        >
                            <input
                                type="radio"
                                :value="lt.id"
                                v-model="personToEdit.inviteForLearningTrackId"
                                :disabled="isLoading || isDisabled || !$store.state.persons.currentCompanyAdmin.id"
                            />
                        </Radiobutton>
                    </span>
                </div>
            </div>

            <div class="form-group flex py-4">
                <checkbox
                    :label="
                        $trans.getTranslation(
                            'toon-veiligheidsinstructies-overtreden',
                            'Toon melding ´Veiligheidsinstructies overtreden`'
                        )
                    "
                    class="mr-1 text-sm"
                >
                    <input
                        type="checkbox"
                        v-model="personToEdit.safetyViolation"
                        :disabled="isLoading || isDisabled || !$store.state.persons.currentCompanyAdmin.id"
                    />
                </checkbox>
                <div
                    class="rounded-full bg-primary-dark w-6 h-6 p-0 text-center p-0 my-1 animation-pulse ml-3 mr-6"
                    v-if="personToEdit && personToEdit.safetyViolation"
                >
                    <alert-icon class="text-orange-200" />
                </div>
            </div>

            <div class="form-group mb-0">
                <label v-trans="'extra-info'">Extra informatie</label>
                <textarea
                    :readonly="isLoading || isDisabled || !$store.state.persons.currentCompanyAdmin.id"
                    class="form-control"
                    cols="50"
                    rows="2"
                    type="text"
                    v-model="personToEdit.info"
                >
                </textarea>
            </div>

            <div class="form-group flex items-center alert alert--info p-2 rounded-tl-none rounded-tr-none">
                <InformationOutline class="text-2xl mr-3 mb-1 pl-2" />
                <p class="text-sm ml-3" v-trans="'only-editable-for-aleris-info'">
                    Dit veld kan enkel door een Aluminimum Duffel medewerker aangepast worden maar is wel zichtbaar voor
                    iedereen.
                </p>
            </div>

            <div
                class="form-group mb-5 pt-2"
                v-show="
                    personToEdit.guid &&
                    ($store.state.persons.currentCompanyAdmin.id || $store.state.persons.currentContractorAdmin.id)
                "
            >
                <div class="text-sm alert alert--info">
                    <p class="mb-2 flex items-center">
                        <InformationOutline class="text-2xl mr-4 mb-1" />
                        <span v-trans="'code'">Code: </span>&nbsp;<span>{{ personToEdit.guid }}</span>
                    </p>
                    <p v-trans="'guid-info1'">
                        Deze code kan gebruikt worden om de contractor medewerker manueel te laten inloggen via de site.
                    </p>
                    <p v-trans="'guid-info2'">
                        Je kan ook deze link aan de contractor medewerker bezorgen om automatisch in te loggen:
                    </p>
                    <p>
                        <a class="text-primary-normal" :href="loginLink" target="_blank">{{ loginLink }}</a>
                    </p>
                </div>
            </div>

            <div class="text-primary-normal pt-4 alert alert--danger" v-if="error" v-html="error"></div>

            <div class="flex justify-between" v-show="!isDisabled" v-if="!$store.getters.isViewer">
                <div
                    v-if="!showCancelButton && personToEdit && $store.state.persons.currentCompanyAdmin.id"
                    v-show="true"
                    class="border rounded-lg mt-4 bg-white"
                    style="
                        padding: 0.15cm;
                        width: 8.5cm;
                        height: 5.4cm;
                        box-shadow: 6px 6px 32px -5px rgba(186, 184, 186, 1);
                    "
                >
                    <badgeToPrint ref="printBadge" :person-to-edit="personToEdit" :visits="visits" />
                </div>
                <button
                    @click.prevent="printBadge"
                    class="btn mr-3 btn--icon h-12"
                    v-if="$store.state.persons.currentCompanyAdmin.id && personToEdit.id"
                >
                    <span v-trans="'print'">Afdrukken</span>
                    <AccountCardDetailsOutline />
                </button>
                <button @click.prevent="cancel" class="btn mr-3 h-12" v-show="showCancelButton" v-trans="'cancel'">
                    Annuleren
                </button>

                <button @click.prevent="savePerson" class="btn btn--primary btn--icon h-12" type="submit">
                    <span v-if="!personToEdit.id && personToEdit.email" v-trans="'save-and-invite'"
                        >Opslaan & Uitnodigen</span
                    >
                    <span v-else v-trans="'save'">Opslaan</span>
                    <arrow-right-icon />
                </button>
            </div>

            <hr v-if="personToEdit.id" />

            <div class="form-group mb-5 pt-4" v-if="personToEdit.id">
                <div class="flex justify-between mb-3">
                    <p class="mb-2 font-bold" v-trans="'latest-security-tests'">Laatste veiligheidstesten:</p>
                    <InvitePersons
                        v-if="$store.state.persons.currentCompanyAdmin.id"
                        :persons="[personToEdit]"
                        :popup-left="false"
                        @refetchPersons="initPerson(person, false)"
                    />
                </div>

                <learning-track-pills
                    :person="personToEdit"
                    @updatedLearningTrack="initPerson(person, false)"
                    :editable="$store.getters.isPortalAdmin"
                    class="enlarge-tl text-lg"
                />
            </div>

            <div class="form-group mb-5" v-if="visits.length > 0 && personToEdit.id">
                <p class="pt-4 mb-3 font-bold">
                    <span v-trans="'latest-visits'">Laatste 10 bezoeken:</span>
                    <button class="btn ml-4 h-12" @click="initPerson(person, false)">
                        <RefreshIcon />
                        Ververs
                    </button>
                </p>
                <table class="w-full">
                    <thead>
                        <tr>
                            <th v-trans="'from'">Van</th>
                            <th v-trans="'to'">Tot</th>
                            <th v-trans="'duration'">Duur</th>
                            <th v-trans="'info'">Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="visit.id" v-for="visit in visits">
                            <td>{{ Utils.toLocaleDateTimeString(visit.dateCreated) }}</td>
                            <td>
                                <p v-if="visit.status === 'expired'">
                                    {{ Utils.toLocaleDateTimeString(visit.expiryDate) }}
                                </p>
                                <div v-if="visit.status !== 'expired'">
                                    <p><strong v-trans="'present-now'">Nu aanwezig</strong></p>

                                    <button
                                        v-if="!$store.getters.isViewer"
                                        :title="
                                            getVisitDuration(visit) === '0:00'
                                                ? `Uitchecken kan pas na ${secondsBeforeLogoutIsEnabled}s`
                                                : ''
                                        "
                                        :disabled="getVisitDuration(visit, true) < secondsBeforeLogoutIsEnabled"
                                        class="btn mt-2 py-0.5"
                                        @click="checkOutAndRefresh(visit)"
                                    >
                                        <p class="-mb-1">Uitchecken</p>
                                        <p
                                            class="text-xs"
                                            :key="changeEachSecond"
                                            v-if="getVisitDuration(visit, true) < secondsBeforeLogoutIsEnabled"
                                        >
                                            in {{ secondsBeforeLogoutIsEnabled - getVisitDuration(visit, true) }} sec
                                        </p>
                                    </button>
                                </div>
                            </td>
                            <td>
                                <p>
                                    {{ getVisitDuration(visit) }}
                                </p>
                            </td>
                            <td>
                                <VisitInfo :visit="visit" :person="person" v-if="visit" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>

        <div class="c-preloader mt-4 c-preloader--fixed" v-if="isLoading"><i></i><i></i><i></i></div>

        <div class="c-preloader mt-4 c-preloader" v-if="isLoadingVisits"><i></i><i></i><i></i></div>
    </div>
</template>

<script>
import { CREATE_LEARNING_TRACK_RESULT, GET_PERSON_BY_GUID2, SAVE_PERSON2 } from "../graphql/queries/Person";
import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";
import CameraIcon from "vue-material-design-icons/Camera.vue";
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";
import AccountCardDetailsOutline from "vue-material-design-icons/CardAccountDetailsOutline.vue";
import RefreshIcon from "vue-material-design-icons/Refresh.vue";
import AlertIcon from "vue-material-design-icons/Alert.vue";

import { GET_ALL_LANGUAGES_QUERY } from "../graphql/queries/Language";
import { GET_ALL_COMPANIES_QUERY } from "../graphql/queries/Company";

import { Utils } from "../utils/utils";
import { GET_VISITS_FOR_PERSON_ID2 } from "../graphql/queries/Visit";
import LearningTrackPills from "./LearningTrackPills";
import { COMPANY_TYPE, LEARNING_TRACK_STATUS } from "../store/constants";
import AsteriskIcon from "./AsteriskIcon";
import BadgeToPrint from "./BadgeToPrint";
import VisitInfo from "@/components/VisitInfo";
import checkbox from "@/components/Checkbox";
import InvitePersons from "@/components/InvitePersons";
import Radiobutton from "@/components/Radiobutton";

export default {
    name: "PersonEdit",
    components: {
        Radiobutton,
        InvitePersons,
        VisitInfo,
        BadgeToPrint,
        LearningTrackPills,
        // DynamicButtonlessModal,
        CapturePhoto: () => import(/* webpackChunkName: "capture-photo" */ "./CapturePhoto"),
        ArrowRightIcon,
        CameraIcon,
        AccountCardDetailsOutline,
        InformationOutline,
        AsteriskIcon,
        RefreshIcon,
        AlertIcon,
        checkbox,
    },
    props: {
        person: {
            type: Object,
        },
        showCancelButton: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            Utils,
            showCapturePhoto: false,
            personToEdit: null,
            visits: [],
            isLoading: false,
            isLoadingVisits: false,
            error: "",
            assetIdToDelete: null,
            isCompanyLoading: false,
            changeEachSecond: 0,
            secondsBeforeLogoutIsEnabled: 60,
        };
    },

    watch: {
        //get lastest data from craft
        person(newPerson) {
            console.log("watch person:", newPerson);
            this.initPerson(newPerson, false);
        },
    },

    computed: {
        isDisabled() {
            return this.personToEdit.status === "disabled";
        },
        loginLink() {
            //index.html is required!
            return `${window.location.origin}/?guid=${this.personToEdit.guid}`;
        },
    },

    apollo: {
        companies: {
            query: GET_ALL_COMPANIES_QUERY,
            fetchPolicy: "cache-first",
            variables() {
                return {
                    status: this.status,
                    companyTypes: [COMPANY_TYPE.CONTRACTOR, COMPANY_TYPE.TRANSPORT],
                };
            },
            update(data) {
                if (data.entries) {
                    return data.entries;
                } else {
                    return [];
                }
            },
            watchLoading(isLoading) {
                this.isCompanyLoading = isLoading;
            },
            skip() {
                return this.$store.state.persons.currentCompanyAdmin.id === null;
            },
        },
        languages: {
            query: GET_ALL_LANGUAGES_QUERY,
            fetchPolicy: "cache-first",
            update(data) {
                if (data.categories) {
                    return data.categories;
                } else {
                    return [];
                }
            },
        },
    },

    mounted() {
        this.initPerson(this.person);
        setInterval(() => {
            this.changeEachSecond++;
        }, 1000);
    },

    methods: {
        initPerson(newPerson, focusField = true) {
            console.log("initPerson:", newPerson);
            if (this.$refs.firstName && focusField) {
                this.$refs.firstName.focus();
            }

            this.init(newPerson);

            this.personToEdit = JSON.parse(JSON.stringify(newPerson));

            //no admin: set default value for learning track contractor
            if (!newPerson.guid && !this.$store.state.persons.currentCompanyAdmin.id) {
                this.personToEdit.inviteForLearningTrackId = this.$store.state.persons.learningTracks.find(
                    (lt) => lt.slug === "contractors"
                ).id;
            }

            if (newPerson.guid) {
                // console.log("Tom: GET_PERSON_BY_GUID2");
                //Get latest data
                this.isLoading = true;
                this.$apollo
                    .query({
                        query: GET_PERSON_BY_GUID2,
                        variables: { guid: [newPerson.guid] },
                        fetchPolicy: "network-only", //no cache!
                    })
                    .then((response) => {
                        // console.log("Tom: GET_PERSON_BY_GUID2 done");
                        this.isLoading = false;
                        if (response.data && response.data.entries.length > 0) {
                            let newPerson = JSON.parse(JSON.stringify(response.data.entries[0]));
                            this.init(newPerson);
                            // console.log("Gert: newPerson:", newPerson);
                            this.personToEdit = JSON.parse(JSON.stringify(newPerson));
                            //this.personToEdit.learningTrackResults = [...newPerson.learningTrackResults];
                            console.log("learningTrackResults", this.personToEdit.learningTrackResults);
                        } else {
                            console.log("GET_PERSON_BY_GUID2 error", response);
                            this.error = response.errors[0].message;
                            throw new Error("GET_PERSON_BY_GUID2");
                        }
                    });

                //get visits of this person
                this.isLoadingVisits = true;
                this.getVisitsForPerson(newPerson);
            }
        },

        getVisitsForPerson(newPerson) {
            this.$apollo
                .query({
                    query: GET_VISITS_FOR_PERSON_ID2,
                    variables: { personIds: [{ id: parseInt(newPerson.id) }] },
                    fetchPolicy: "network-only",
                })
                .then((response) => {
                    // console.log("Gert: response:", response);
                    if (response.data && response.data.entries) {
                        this.visits = response.data.entries;
                        this.isLoadingVisits = false;
                        // console.log("this.visits", this.visits);
                    } else {
                        throw new Error("GET_VISITS_FOR_PERSON_ID");
                    }
                });
        },

        getVisitDuration(visit, returnSeconds = false) {
            // console.log("getVisitDuration", visit, returnSeconds);
            let start, end, diff;
            if (visit) {
                start = new Date(visit.dateCreated);
                end = visit.status === "expired" ? new Date(visit.expiryDate) : new Date();
                diff = end.getTime() - start.getTime();
            } else {
                return returnSeconds ? 0 : "0:00";
            }
            if (visit && visit.expiryDate) {
                if (returnSeconds) {
                    return Math.round(diff / 1000);
                } else {
                    //only if >= 1 minute
                    if (diff >= 60000) {
                        const hours = Math.floor(diff / (1000 * 60 * 60));
                        let minutes = Math.floor((diff / (1000 * 60)) % 60);
                        if (minutes < 10) {
                            minutes = "0" + minutes;
                        }
                        return hours + ":" + minutes;
                    } else {
                        return "0:00";
                    }
                }
            } else {
                return returnSeconds ? Math.round(diff / 1000) : "";
            }
        },

        printBadge() {
            let printwindow = window.open("", "PRINT", "height=400,width=600");

            printwindow.document.write("<html><head><title></title>");

            //for production mode
            document.querySelectorAll("link").forEach((link) => {
                if (link.as === "style") {
                    // console.log("Gert: style found:", link.href);
                    const head = printwindow.document.head;
                    const linkEl = printwindow.document.createElement("link");
                    linkEl.type = "text/css";
                    linkEl.rel = "stylesheet";
                    linkEl.href = link.href;
                    head.appendChild(linkEl);
                }
            });

            //for dev mode
            document.querySelectorAll("style").forEach((style) => {
                printwindow.document.writeln("<style>" + style.innerText + "</style>");
            });

            document.querySelectorAll("link").forEach((el) => console.log(el.as));

            // printwindow.document.write("<style>a,body,div,html,p,span,table{font-family:'Helvetica Neue',Helvetica,Arial,Verdana,sans-serif}.justify-between{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.flex{display:-webkit-box;display:-ms-flexbox;display:flex}.items-end{-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end}.flex-col{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.h4{font-weight:500;font-size:1.5rem;margin-bottom:.5rem}.h5{font-weight:500;font-size:1.25rem;margin-bottom:.5rem}blockquote,dd,dl,figure,h1,h2,h3,h4,h5,h6,hr,p,pre{margin:0}body,html{color:#505050}html{line-height:1.5}.w-24{width:6rem}.pt-4{padding-top:1rem}img,video{max-width:100%;height:auto}audio,canvas,embed,iframe,img,object,svg,video{display:block;vertical-align:middle}img{border-style:none}.rounded-lg{border-radius:.5rem}.mx-2{margin-left:0.5rem;margin-right:0.5rem;}</style>");

            printwindow.document.write("</head><body >");
            printwindow.document.write(document.getElementById("print-badge").innerHTML);
            printwindow.document.write("</body></html>");

            printwindow.document.close(); // necessary for IE >= 10
            printwindow.focus(); // necessary for IE >= 10*/

            setTimeout(() => {
                printwindow.print();
            }, 500);

            return true;
        },

        init(person) {
            // console.log("init", person);
            if (!person.firstName) {
                person.firstName = "";
            }
            if (!person.lastName) {
                person.lastName = "";
            }
            if (!person.email) {
                person.email = "";
            }

            if (!(person.company && person.company.length > 0)) {
                person.company = [{ id: null, title: person.companyName }];
            }
            if (!person.languageCategorie) {
                person.languageCategorie = [];
            }
            if (person.languageCategorie.length === 0) {
                person.languageCategorie.push({ id: 248, title: "Nederlands" });
            }
            if (!person.photo) {
                person.photo = [];
            }
        },

        savePerson() {
            this.error = "";

            if (this.personToEdit.firstName === "") {
                this.error +=
                    this.$trans.getTranslation("first-name-cannot-be-blank", "Voornaam mag niet leeg zijn") + "<br>";
            }
            if (this.personToEdit.lastName === "") {
                this.error +=
                    this.$trans.getTranslation("last-name-cannot-be-blank", "Familienaam mag niet leeg zijn") + "<br>";
            }
            //check for valid learning track, but only if it's a new person (!this.personToEdit.id)
            if (!this.personToEdit.id && !this.personToEdit.inviteForLearningTrackId) {
                this.error +=
                    this.$trans.getTranslation("select-learning-track", "Selecteer een veiligheidstest") + "<br>";
            }

            if (this.error !== "") {
                return;
            }

            // console.log("this.personToEdit.company[0].id", this.personToEdit.company[0].id);
            this.isLoading = true;
            this.$apollo
                .mutate({
                    mutation: SAVE_PERSON2,
                    variables: {
                        id: this.personToEdit.id,
                        firstName: this.personToEdit.firstName,
                        lastName: this.personToEdit.lastName,
                        email: this.personToEdit.email,
                        languageIds: [parseInt(this.personToEdit.languageCategorie[0].id)],
                        companyIds: [parseInt(this.personToEdit.company[0].id)],
                        photoIds: this.personToEdit.photo.map((photo) => {
                            return parseInt(photo.id);
                        }),
                        licensePlate: this.personToEdit.licensePlate,
                        mobilePhone: this.personToEdit.mobilePhone,
                        info: this.personToEdit.info,
                        safetyViolation: this.personToEdit.safetyViolation,
                    },
                    //fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                })
                .then((e) => {
                    // console.log("THEN", e.data.upsertPerson);

                    // if (this.assetIdToDelete) {
                    // 	this.deleteEarlierPhoto(this.assetIdToDelete);
                    // }

                    // TODO: if it's a new person, so if we start out without an id
                    // also send an invite when we're in this callback
                    if (!this.personToEdit.id) {
                        this.$apollo
                            .mutate({
                                mutation: CREATE_LEARNING_TRACK_RESULT,
                                variables: {
                                    id: parseInt(e.data.save_person_person_Entry.id),
                                    learningTrack: parseInt(this.personToEdit.inviteForLearningTrackId), // Contractors learningTrack in the db
                                    statusId: LEARNING_TRACK_STATUS.NOT_ATTEMPTED,
                                },
                                fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                                // pollInterval: 1000,
                            })
                            .then((val) => {
                                console.log(
                                    "Learning track result created ",
                                    val.data.save_learningTrackResult_learningTrackResult_Entry
                                );
                                this.personToEdit.learningTrackResults = [
                                    val.data.save_learningTrackResult_learningTrackResult_Entry,
                                ];
                            })
                            .catch(async (e) => {
                                let err;
                                if (e.networkError && e.networkError.result && e.networkError.result.error) {
                                    err = e.networkError.result.error.split("\r\n").join("");
                                } else if (
                                    e.networkError &&
                                    e.networkError.result &&
                                    e.networkError.result.errors &&
                                    e.networkError.result.errors[0] &&
                                    e.networkError.result.errors[0].message
                                ) {
                                    err = e.networkError.result.errors[0].message;
                                } else {
                                    err = e.message;
                                }
                                this.error = this.$trans.getTranslation(err, err);
                            });
                    }
                    this.$emit("saveSucceeded", e.data.save_person_person_Entry);

                    //go to exam page if not an admin
                    if (
                        !this.$store.state.persons.currentCompanyAdmin.id &&
                        !this.$store.state.persons.currentContractorAdmin.id
                    ) {
                        this.$router.push({ name: "onlineContractorLearningPath" });
                    }
                })
                .catch((e) => {
                    let err = "";
                    if (e.networkError && e.networkError.result && e.networkError.result.error) {
                        err = e.networkError.result.error.split("\r\n").join("");

                        if (err === "Email already in use") {
                            this.error =
                                this.$trans.getTranslation(err, err) +
                                ` Zoek het email ${this.personToEdit.email} op via de filter in de personenlijst.`;
                            return;
                        }

                        this.error = this.$trans.getTranslation(err, err);
                    } else if (
                        e.networkError &&
                        e.networkError.result &&
                        e.networkError.result.errors &&
                        e.networkError.result.errors[0] &&
                        e.networkError.result.errors[0].message
                    ) {
                        err = e.networkError.result.errors[0].message;
                        this.error = this.$trans.getTranslation(err, err);
                    } else {
                        /*
                    "Validation failed.

                    - Title cannot be blank.
                    -Slug cannot be blank.
                    -First name cannot be blank.
                    -Last name cannot be blank."
                     */

                        if (e.message.indexOf("Validation failed") > -1) {
                            // Get rid of new lines
                            let messages = e.message.replace(/\n/gi, "");
                            // Get rid of unnecessary messages
                            messages = messages.replace(/- ?(Title|Slug) cannot be blank./gi, "");
                            const validationErrors = messages.split(/- ?/gi);
                            console.log(validationErrors);

                            for (let i = 0; i < validationErrors.length; i++) {
                                const validationError = validationErrors[i];
                                validationErrors[i] = this.$trans.getTranslation(validationError, validationError);
                            }
                            this.error = validationErrors.join("<br>");
                        } else {
                            err = e.message;
                            this.error = this.$trans.getTranslation(err, err);
                        }
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        cancel() {
            this.$emit("cancel");
        },

        onSavePhotoCapture(dataUrl) {
            if (this.personToEdit.photo.length > 0) {
                this.assetIdToDelete = this.personToEdit.photo[0].id;
            } else {
                this.assetIdToDelete = null;
            }

            this.showCapturePhoto = false;
            //console.log("dataUr:l", dataUrl);
            const isoDate = new Date().toISOString().split(":").join("-");
            this.$axios({
                url: `rest/v1/asset/upload`,
                method: "post",
                //responseType: "json",
                headers: {
                    Accept: "application/json",
                },
                data: {
                    content: dataUrl,
                    type: "pictures",
                    title: `selfie-${this.personToEdit.firstName}-${this.personToEdit.lastName}-${isoDate}`,
                },
            })
                .then((response) => {
                    // console.log("Gert: response:", response);
                    this.personToEdit.photo = [{ id: response.data.id }];
                    this.savePerson();
                })
                .catch((reject) => {
                    console.log("Error: reject:", reject);
                })
                .finally(() => {
                    // console.log("Gert: done!");
                });
        },

        async checkOutAndRefresh(visit) {
            let newExpiryDate = new Date(new Date().getTime() - 1000 * 60).toISOString(); //check out a minute ago, to make sure the logout works right away

            if (visit.dateCreated && new Date(newExpiryDate) <= new Date(visit.dateCreated)) {
                console.log("correct expiry because of dateCreated", visit.dateCreated, newExpiryDate);
                //always make sure the post date is later than the expiry
                newExpiryDate = visit.dateCreated;
            }
            visit.expiryDate = newExpiryDate;
            visit.status = "expired"; //this is only set for internal checking,but the backend will also take care of it
            console.log("checkOutAndRefresh", visit);

            //IMPORTANT: No update query needed, because the visit is already watched and saved in VisitInfo.vue!

            //refresh
            // setTimeout(() => {
            //     // this.initPerson(this.person, false);
            //     this.getVisitsForPerson(this.person);
            // }, 3000);
        },
    },
};
</script>

<style scoped>
.animation-pulse {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgb(42, 173, 199);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(42, 173, 199, 0);
    }

    100% {
        transform: scale(0.9);
    }
}
</style>
